/* InsightsAndGraph.css */
.linegraphContainer {
  width: 100%;
  height: 150px;
  overflow: hidden;
  max-width: 100%;
}
.insightsGraphMainContainer {
  box-shadow: 0px 3.499999761581421px 5.5px rgba(0, 0, 0, 0.02);
}
@media (max-width: 2700px) {
  .linegraphContainer {
    max-width: 80% !important;
  }
}
@media (max-width: 2200px) {
  .linegraphContainer {
    max-width: 60% !important;
  }
}

/* @media (max-width: 1760px) {
  .linegraphContainer {
    max-width: 230px !important;
  }
}

@media (max-width: 1700px) {
  .linegraphContainer {
    max-width: 200px !important;
  }
}

@media (max-width: 1650px) {
  .linegraphContainer {
    max-width: 180px !important;
  }
} */

@media (max-width: 1550px) {
  .linegraphContainer {
    max-width: 100% !important;
  }
  /* .insightsGraphMainContainer {
    flex-direction: column !important;
  } */
  .insightsContainer {
    width: 100%;
    /* flex-direction: row-reverse !important; */
  }
}
/* @media (max-width: 1400px) {
  .linegraphContainer {
    max-width: 270px !important;
  }
}
@media (max-width: 1300px) {
  .linegraphContainer {
    max-width: 235px !important;
  }
} */
@media (max-width: 1200px) {
  .linegraphContainer {
    max-width: 70% !important;
  }
  .insightsGraphMainContainer {
    flex-direction: row !important;
  }
  .insightsContainer {
    width: 163.8px;
    flex-direction: column-reverse !important;
  }
}
@media (max-width: 544px) {
  .linegraphContainer {
    max-width: 100% !important;
  }
  .insightsGraphMainContainer {
    flex-direction: column !important;
  }
  .insightsContainer {
    width: 100%;
    flex-direction: row-reverse !important;
  }
}
