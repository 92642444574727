.responsive-textarea {
    width: 100%; /* Set the width to 100% to fill the entire available width */
    border: 0.5px solid #cccccc88;
    padding: 5px 15px;
    resize: vertical; /* Allow vertical resizing */
    height: 100px; /* Default height for small screens (adjust as needed) */
    border-radius: 5px;
}

  /* Media queries for responsiveness */
@media (min-width: 768px) {
    .responsive-textarea {
      height: 120px;
    }
  }
  
  @media (min-width: 992px) {
    .responsive-textarea {
      height: 40px;
    }
  }