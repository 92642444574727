* {
  font-family: Inter;
}
.logo-container {
  margin-left: 50px;
  justify-content: space-between;
  width: "100%";
}
.agnext-logo {
  margin-top: 20px;
  width: 104px;
  height: 42px;
}
.agnext-logo-position {
  /* margin-right: 100px; */
}
.drawer-logo-closed {
  width: 24px;
  height: 24px;
  margin-top: -32px;
  padding-left: 18px;
}
.drawer-logo {
  width: 24px;
  height: 24px;
  margin-top: 25px;
  padding-left: 35px;
}
.sidebar-content {
  font-size: 14px;
  line-height: 16.8px;
  font-weight: 600;
  font-family: Inter;
  color: #1a202c;
  text-align: center;
}

.sidebar-content-container {
  display: flex;
  width: 219.5px;
  height: 45px;
  border-radius: 12px;
  align-items: center;
}

.sidebar-close {
  width: 50px !important;
  font-size: 0px !important;
  position: relative;
}

.sidebar-close-seperator {
  position: absolute;
  top: 90px;
  left: 30px;
}
.icon-box-sidebar {
  padding: 19.5px 12px 19.5px 23.5px;
}
.icon-box {
  width: 50px;
  border-radius: 15px;
  height: 46px;
  padding: 8px 5px 20px 10px;
  margin: 12px 0 0 15px;
}
.logo-position {
  position: absolute;
  top: 65px;
  left: 5px;
}
