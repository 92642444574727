.color-gradient {
    position: absolute;
    width: 10%;
    bottom: 20px;
    left: 50px;
    height: 10px;
    background: linear-gradient(to right, #4EC3B9, #1B776F); /* Customize the gradient colors */
  }
  
  .color-gradient-bar {
    width: 100%;
    height: 100%;
    border-radius: 0 0 10px 10px; /* Add rounded corners to the bottom of the bar */
  }

  .buttonStyle{
    padding: 1px !important;
    border-radius: 5px !important;
    filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.22));
    bottom: 20px;
    right: 30px;
    border-radius: 15px;
    border: 0.5px solid #E2E8F0;
    background: #FFF !important;
  }
  .tooltip{
    display: flex;
    width: 300px;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    position: absolute;
    border-radius: 15px;
    border: 1px solid #F8F8F8;
    background: #FFF;
    /* 2xl */
    box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.25);
  }
  .tooltipHeading{
    color: #718096;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    padding-left: 5px;
  }
  .textLeftContent {
    color: #768AA3;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; 
    float: left;
    padding: 10px; 
  }
  .textRightContent {
    padding: 10px;
    color: #637489;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; 
    float: right;
  }
  .rowMap{
    border-bottom: var(--chakra-borders-1px);
    border-color: var(--chakra-colors-gray-100);
    color: var(--chakra-colors-table-data);
  }