.outer-container {
  width: 100%;
  height: 100vh;
  background: #fff;
  padding: 36px 0px 37px 32px;
  display: flex;
}

.inner-container {
  width: 56.05%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 787px !important;
  padding: 0% 10%;
}
.logo {
  margin-bottom: 8px;
  width: 169px;
  height: 49px;
}
.login {
  display: flex;
  flex-direction: column;
  row-gap: 36px;
  width: 55.6% !important;
}
.header {
  color: #4fd1c5;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}
.title {
  color: #a0aec0;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
}
.input-container {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.input {
  width: 100%;
  height: 50px;
  padding: 0px 5%;
  &::placeholder {
    font-size: 16px;
    align-items: center;
  }
}
.input-title {
  color: #2d3748;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.login-submit-btn {
  padding: 10px;
  width: 100%;
}

.remember {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}
.forgot-btn {
  color: #4fd1c5;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding-right: 10px;
}

@media scree {
}
.input-error {
  border-color: red !important;
}
.input-error-message {
  color: red;
  font-size: 14px;
  margin-top: 4px;
}

/* Media Query */

@media screen and (max-width: 1550px) {
  .login {
    width: 65% !important;
  }
  .inner-container {
    min-height: 666px !important;
  }
}
@media screen and (max-width: 1365px) {
  .login {
    width: 80% !important;
    row-gap: 30px;
  }
  .inner-container {
    padding: 7%;
  }
  .inner-container {
    min-height: 566px !important;
  }
}
@media screen and (max-width: 1170) {
  .inner-container {
    min-height: 500px !important;
  }
}

@media screen and (max-width: 890px) {
  .login {
    width: 100% !important;
    row-gap: 25px;
  }
}
@media screen and (max-width: 600px) {
  .outer-container {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column-reverse;
    gap: 64px;
    overflow-y: scroll;
    padding: 41px 16px 17px 16px;
  }
  .inner-container {
    width: 100%;
    display: flex;
    padding: 0px 10% !important;
  }
}

@media screen and (max-width: 400px) {
  .outer-container {
    width: 100%;
    padding: 0;
    height: auto;
    display: flex;
    flex-direction: column-reverse;
    gap: 64px;
    overflow-y: scroll;
    padding: 41px 16px 17px 16px;
  }
  .inner-container {
    padding: 0px !important;
  }
}
/* @media screen and (max-width: 500px) {
  .outer-container {
    flex-direction: column-reverse;
  }
} */
