.NavbarContainer {
  display: flex;
  justify-content: space-between;
}
.UserDropdown {
  gap: 20px;
  border-radius: 15px;
  background: #fff;
  flex-direction: column;
}
.UserDetailsContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}
.User {
  color: rgba(0, 0, 0, 0.8);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}
.UserEmail {
  color: rgba(0, 0, 0, 0.64);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.AccountBtn,
.LogoutBtn {
  display: unset;
  height: 48px;
  border-radius: 8px !important;
  width: 100%;
  background: #f7fafc;
  text-align: left;
}
.UserDropdownBtnContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
