.tooltipDesign {
  display: flex;
  width: 223px;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 15px;
  border: 1px solid #cbd5e0;
  background: white;
  box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.25);
  opacity: 1;
  pointer-events: none;
  position: absolute;
  transform: translate(-50%, 0);
  transition: all 0.1s ease;
}
ul.tooltipul li {
  display: flex;
  padding: 4px 0px;
  justify-content: space-between !important;
  /* align-items: center; */
  align-self: stretch;
  border-bottom: 1px solid #f8f8f8;
  color: #4a5568;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.tooltipDesign::before {
  content: "";
  position: absolute;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent #ffffff transparent;
  left: 50%;
  bottom: -20px;
  transform: translateX(-50%) rotate(180deg);
}

/* Style for the tooltip content */
.tooltipul {
  width: 100%;

  list-style: none;
  margin: 0;
  padding: 0;
}

/* .tooltipul li {
  margin-bottom: 8px;
} */
#tooltipData {
  color: #718096;
  width: 100%;
  display: flex;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  justify-content: flex-end;
  color: #718096;
}
.custom-span {
  font-weight: 600;
}
ul.tooltipul1 li {
  display: flex;
  flex-direction: row;
  width: fit-content;
  gap: 10px;
  padding: 8px;
  justify-content: space-between !important;
  /* align-items: center; */
  align-self: stretch;
  color: #4a5568;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.tooltipDesign1 {
  display: flex;
  border-radius: 8px;
  border: 1px solid #cbd5e0;
  background: white;
  box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.25);
  opacity: 1;
  pointer-events: none;
  position: absolute;
  transform: translate(-50%, 0);
  transition: all 0.1s ease;
}
