.forgot-pass {
  display: flex;
  flex-direction: column;
  row-gap: 36px;
  width: 55.6%;
}
.fp-btn {
  width: 50%;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
}
@media screen and (max-width: 1460px) {
  .forgot-pass {
    width: 65% !important;
  }
}
@media screen and (max-width: 1251px) {
  .forgot-pass {
    width: 80% !important;
    row-gap: 30px;
  }
  .inner-container {
    padding: 7%;
  }
}
@media screen and (max-width: 890px) {
  .forgot-pass {
    width: 100% !important;
    row-gap: 25px;
  }
}
