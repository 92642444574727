/* Index */
* {
  font-family: Inter !important;
}
.insights-container {
  display: flex;
  height: auto;
  column-gap: 10px;
  margin: 32px 0 32px 0;
  flex-direction: row;
}
.counts-container {
  display: flex;
  flex-wrap: wrap;
  /* flex-direction: row; */
  row-gap: 16px;
  column-gap: 16px;
  /* margin-bottom: 5px; */
}
.ChartStats {
  width: 41%;
}

/* Audit Overview */
.ChartHeading {
  /* font-family: Inter; */
  font-size: 14px;
  /* font-style: normal; */
  font-weight: 500;
  /* line-height: "25.2px"; */
}
.AuditsCount {
  color: #2d3748;
  /* font-family: Inter; */
  font-size: 18px;
  /* font-style: normal; */
  font-weight: 700;
  line-height: "18px";
}
.ChartHeadingLayout {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  flex: 1 0 0;
  line-height: "14px";
  padding: 19px 0px 8px 20px;
}
.ChartCard {
  flex-direction: column;
  background-color: white;
  border-radius: 15px;
  padding: "0";
}

/* Insights */
.stat-card {
  display: flex;
  min-width: 200px !important; /* 300px */
  width: 100%;
  padding: 20px;
  flex-direction: column;
  gap: 10px;
  flex: 1 0 0;
  background-color: white;
  border-radius: "15px";
}
.stat-label {
  font-size: 14px !important;
  color: #7e8da2 !important;
  font-weight: 500 !important;
  line-height: 150%;
}
.stat-number {
  font-size: 28px !important;
  font-weight: 700;
  color: #2d3748;
  line-height: 140%;
}
.StatHelp {
  color: var(--green-green-400, #48bb78);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 19.6px;
}

/* Inspection Table */

.action-btn-dropdown {
  width: 322px !important;
  padding: 20px;
  border-radius: 15px !important;
  position: absolute !important;
  box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
}
.view-report-btn,
.download-report-btn {
  padding: 0px 24px !important;
  font-size: 18px !important;
  display: flex;
  justify-content: space-between !important;
  width: 100%;
  border-radius: 6px !important;
  height: 48px !important;
}
.table-row {
  font-family: Inter;
  overflow: hidden;
  color: #4a5568;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  padding: 16px 24px;
  height: 69.2px;
}
.table-head {
  font-family: Inter;
  color: #718096;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  padding: 12px 24px;
  align-items: center;
  gap: 11px;
  height: 69.2px;
  /* max-width: 201px; */
}

/* Column Filter */

.ColumnFilterContainer {
  display: flex;
  width: 39px;
  height: 39px;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 0.5px solid var #e2e8f0;
  background: white;
  cursor: pointer;
  font-family: Inter;
}
.column-customize-label {
  padding: 8px;
  align-items: center;
}
.column-customize-label:hover {
  background-color: #f7fafc;
}
.dropdownOption {
  font-family: Inter;
  font-size: 16px !important;
  padding: 0 5px 0 5px;
}
.drag-btn {
  display: none;
}
.column-customize-label:hover .drag-btn {
  cursor: grab;
  width: 13.333px;
  height: 20px;
  display: block !important; /* or any other appropriate display value */
}
.reset-btn {
  border-radius: 6px !important;
  width: 100% !important;
}

/* Column Filter */
.customize-column {
  display: flex !important;
  width: 400px !important;
  padding: 20px;
  flex-direction: column;
  row-gap: 20px !important;
  border-radius: 15px !important;
  font-family: Inter;
  position: absolute;
  top: 43px;
  left: -360px;
  z-index: 999;
  box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  background-color: #fff;
}
.customize-column-mobile {
  display: flex !important;
  width: 280px !important;
  padding: 20px;
  flex-direction: column;
  row-gap: 20px !important;
  border-radius: 15px !important;
  font-family: Inter;
  position: absolute;
  top: 43px;
  left: -240px;
  z-index: 999;
  box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
  background-color: #fff;
}
/* Date Picker */

.date-clear-btn {
  display: flex;
  width: 106px !important;
  height: 40px;
  padding: 0px 8px;
  /* align-items: center; */
  flex-shrink: 0;
  border-radius: 12px;
  background: #edf2f7;
  /* align-self: flex-end; */
  margin: 0 20px 10px 0;
}
.custom-datepicker {
  margin-top: 16px;
  height: 24px;
  &::placeholder {
    font-size: 14px;
  }
}

.close-datepicker-btn {
  display: flex;
  width: 106px !important;
  height: 40px;
  padding: 0px 8px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 12px;
  background: #4fd1c5 !important;
}

.dynamic-dates {
  width: 128px;
  color: #2d3748;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  margin: 16px;
  cursor: pointer;
}
.dynamic-dates:hover {
  color: black;
  cursor: pointer;
  font-weight: bold;
}
.date-picker-container {
  display: flex;
  column-gap: 2.3%;
  margin-left: 17px;
}
.date-picker-btn-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 380px;
  width: 100%;
}
.date-picker-main-container {
  min-width: 685px !important;
  height: 384px !important;
  margin-top: 170px !important;
  left: -52px !important;
  border-radius: 15px !important;
  box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.25) !important;
}

.email-head {
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 150% !important;
  color: #718096 !important;
  padding: 0px !important;
}

.warehouse-filter-dropdown-container {
  /* width: 300px !important; */
  padding: 20px;
  border-radius: 15px !important;
  position: absolute !important;
  display: flex !important;
  flex-direction: column;
  position: absolute;
  top: 43px;
  z-index: 1;
  box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.25);
  background-color: white;
  overflow-y: auto !important;
  z-index: 1000;
}
.scrollable-dropdown {
  max-height: 300px;
  overflow-y: auto;
  width: 100%;
}

::-webkit-scrollbarY {
  /* width: 0.2em; */
  /* height: 0px; */
}

::-webkit-scrollbar-thumb {
  background-color: #c5c5c5;
  /* border-radius: 6px; */
  width: 0.01px !important;
  /* margin-right: 0.01px; */
}
.name-location-container {
  width: 223px;
  box-sizing: border-box;
  display: flex;
  padding: 12px 16px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  &:hover {
    background-color: #f7fafc;
    cursor: pointer;
  }
}

.warehouse-name-text {
  color: #4a5568;
  /* font-family: Open Sans !important; */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.search-filter-container {
  /* height: 72px; */
  background-color: white;
  border-radius: 15px;
  padding: 16px 20px;
  justify-content: space-between;
  box-shadow: 0px 3.49px 5.5px 0px rgba(0, 0, 0, 0.02);
}

.warehouse-location-text {
  color: #718096;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 175%;
}

.spacing {
  margin-bottom: 32px;
}

.hide-insights {
  display: none !important;
}

/* Add Filter */
.drop-Down {
  display: flex !important;
  position: absolute !important;
  right: 0px;
  top: 43px;
  display: flex;
  width: 322px;
  row-gap: 20px !important;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 15px !important;
  background: white;
  box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.25);
  z-index: 999;
}

/* for mobile*/
.drop-Down-mobile {
  display: flex !important;
  position: absolute !important;
  right: 0px;
  top: 43px;
  display: flex;
  width: 260px;
  row-gap: 20px !important;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 15px !important;
  background: white;
  box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.25);
  z-index: 999;
}

/*view report*/
.drop-Down2 {
  display: flex !important;
  position: absolute !important;
  right: 10px;
  top: 143px;
  display: flex;
  width: 189px;
  row-gap: 20px !important;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 15px !important;
  background: white;
  box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.25);
  z-index: 999;
}

.hide {
  display: none !important;
}
.side-drop-down {
  display: flex !important;
  position: absolute !important;
  top: 0px;
  right: 308px;
  width: 274px;
  row-gap: 12px !important;
  padding: 20px 8px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 15px !important;
  background: white;
  box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.25);
  z-index: 1;
}
.side-drop-down-mobile {
  display: flex !important;
  position: absolute !important;
  top: 50px;
  right: 0px;
  width: 219px;
  row-gap: 12px !important;
  padding: 20px 8px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 15px !important;
  background: white;
  box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.25);
  z-index: 1;
}

.add-filter-btn {
  padding: 0px 16px !important;
  font-size: 16px !important;
  display: flex;
  justify-content: space-between !important;
  width: 100% !important;
  border-radius: 6px !important;
  height: 48px !important;
  padding: 0px 48px 0px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border: 1px solid #a0aec0;
  background: #f7fafc;
}

/* Media Query */

/* @media screen and (min-width: 2287px) {
  .stat-card {
    min-width: 400px !important;
  }
}
included when chart is uncommented.
@media screen and (max-width: 1925px) {
  .stat-card {
    min-width: 250px !important;
  }
} */

@media screen and (max-width: 1672px) {
  .stat-card {
    min-width: 31.5% !important;
  }
}

@media screen and (max-width: 1335px) {
  .stat-label {
    font-size: 12px !important;
  }
  .stat-card {
    min-width: 29% !important;
  }
}

@media screen and (max-width: 1200px) {
  .insights-container {
    flex-direction: column;
  }
  .chart-outer-container {
    width: 100% !important;
    margin-bottom: 16px;
  }
  .counts-container {
    width: 100% !important;
  }
}
@media screen and (max-width: 768px) {
  .stat-card {
    min-width: 48% !important;
  }
}

/* @media screen and (max-width: 576px) {
  .stat-card {
    min-width: 100% !important;
  }  */
/* } */
