.banner-container {
  width: 100%;
  height: calc(100vh - 64px);
  flex-shrink: 0;
  min-height: 787px !important;
  border-radius: 30px;
  background: #185953;
  position: relative;
  overflow: hidden;
}
.image-container {
  border-radius: inherit;
  height: 50%;
  min-height: 450px;
}
.tags-inner-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 60% !important;
  gap: 20px;
}
.tags-container {
  position: absolute;
  top: 40%;
  height: 60% !important;
  padding: 4.5% 9.7%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
}
.tagline {
  color: #fff;
  font-size: 60px;
  font-style: normal;
  font-weight: 600;
}
.quote {
  color: #e6fffa;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}
.rights {
  color: #e6fffa;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

/* Media Query */

@media screen and (max-width: 1550px) {
  .tagline {
    font-size: 50px;
  }
  .quote,
  .rights {
    font-size: 16px;
  }
  .banner-container {
    min-height: 666px !important;
  }
}
@media screen and (max-width: 1365px) {
  .tags-container {
    height: 65% !important;
    top: 35%;
  }
  .tagline {
    font-size: 45px;
  }
  .quote,
  .rights {
    font-size: 16px;
  }
  .banner-container {
    min-height: 566px !important;
  }
}

@media screen and (max-width: 1170px) {
  .tagline {
    font-size: 38px;
  }
  .quote,
  .rights {
    font-size: 14px;
  }
  .banner-container {
    min-height: 500px !important;
  }
}

@media screen and (max-width: 996px) {
  .tagline {
    font-size: 32px;
  }
}
@media screen and (max-width: 843px) {
  .tagline {
    font-size: 26px;
  }
  .quote,
  .rights {
    font-size: 14px;
  }
}
@media screen and (max-width: 780px) {
  .banner-container {
    height: fit-content;
    width: 100%;
    min-height: 0px;
  }
  .quote,
  .rights {
    font-size: 16px;
  }
}
@media screen and (min-width: 600px) and (max-width: 700px) {
  .tagline {
    font-size: 26px;
  }
}
@media screen and (max-width: 600px) {
  .banner-container {
    height: fit-content;
    width: 100%;
  }
  .image-container {
    position: static;
    height: 432px !important;
    overflow: hidden;
  }
  .banner-image {
    width: auto;
    height: 100%;
  }
  .tags-container {
    position: static;
    /* height: 100% ; */
    margin-top: -20%;
    /* z-index: 1; */
    padding: 4.5% 9.7%;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
  }
  .tagline {
    font-size: 24px;
  }
  .quote,
  .rights {
    font-size: 16px;
  }
  .tags-inner-container {
    gap: 36px;
  }
  .tags-container {
    gap: 42px;
  }
}
