.email-input-container {
  display: flex;
  align-items: center;
  align-content: center;
  padding: 12px 2px 12px 16px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  width: 100%;
  min-height: 116px;
  flex-wrap: wrap;
}
.email-badge {
  background-color: #718096;
  color: white;
  border-radius: 6px !important;
  display: flex !important;
  justify-content: space-between;
  align-items: center !important;
  padding: 0px 8px;
  font-family: Inter;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 142.857%;
  gap: 8px;
  margin-bottom: 10px;
  padding: 0;
  text-transform: none !important;
}
.response-message {
  color: rgba(0, 0, 0, 0.64);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
