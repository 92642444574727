.send-otp-container {
  display: flex;
  flex-direction: column;
  row-gap: 36px;
  width: 55.6%;
  /* height: 90%; */
}
.send-otp {
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  width: 100%;
}
.check-spam {
  color: #a0aec0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.not-receive {
  color: #4a5568;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.resend {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.timer {
  color: #38b2ac;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}
.otp-send-btn {
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  margin-top: 11px;
}
@media screen and (max-width: 1460px) {
  .send-otp-container {
    width: 65% !important;
  }
}
@media screen and (max-width: 1251px) {
  .send-otp-container {
    width: 80% !important;
    row-gap: 30px;
  }
  .inner-container {
    padding: 7%;
  }
}
@media screen and (max-width: 890px) {
  .send-otp-container {
    width: 100% !important;
    row-gap: 25px;
  }
}
