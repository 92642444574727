.set-new-password {
  display: flex;
  flex-direction: column;
  row-gap: 36px;
  width: 55.6%;
}
.password-format {
  color: #4a5568;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-left: 8px;
}
@media screen and (max-width: 1460px) {
  .set-new-password {
    width: 65% !important;
  }
}
@media screen and (max-width: 1251px) {
  .set-new-password {
    width: 80% !important;
    row-gap: 30px;
  }
  .inner-container {
    padding: 7%;
  }
}
@media screen and (max-width: 890px) {
  .set-new-password {
    width: 100% !important;
    row-gap: 25px;
  }
}
